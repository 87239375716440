import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, useParams, useNavigate } from 'react-router-dom';
import './App.css';
import { intro, projects } from './data';

function importAll(r) {
  let images = {};
  r.keys().forEach((item) => {
    images[item.replace('./', '')] = r(item);
  });
  return images;
}

const images = importAll(require.context('../public/images', true, /\.(png|jpe?g|svg|gif)$/));

function Intro({ language }) {
  const introText = intro[language];

  return (
    <p>
      <a href="http://punkt.kr" target="_blank" rel="noreferrer noopener" className="intro-link">
        {introText.main}<sup className="intro-superscript">{introText.sub}</sup>
      </a>
      {introText.text}
      <a href="http://pikant.kr" target="_blank" rel="noreferrer noopener" className="intro-link-pikant">
        {introText.studio}<sup className="intro-superscript">{introText.studioSub}</sup>
      </a>
      {introText.suffix}
    </p>
  );
}

function App() {
  const [selectedProject, setSelectedProject] = useState(null);
  const [language, setLanguage] = useState('ko');
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const navigate = useNavigate();

  const handleClick = (project) => {
    setSelectedProject(project);
    setIsSidebarOpen(false); // 사이드바 닫기
    navigate(`/project/${project.id}`);
  };

  const toggleLanguage = () => {
    setLanguage(language === 'ko' ? 'en' : 'ko');
  };

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  return (
    <div className="app-container">
      <div className="header-container">
        <button onClick={toggleSidebar} className="sidebar-toggle-btn">
          {isSidebarOpen ? 'Close' : 'Menu'}
        </button>
        <div className="header-title">
          <h1><a href="http://punkt.kr" rel="noopener noreferrer">punkt.</a></h1>
        </div>
        <button onClick={toggleLanguage} className="language-toggle">
          {language === 'ko' ? 'EN' : 'KO'}
        </button>
      </div>
      <div className={`main-container ${isSidebarOpen ? 'sidebar-open' : ''}`}>
        <div className={`sidebar ${isSidebarOpen ? 'open' : ''}`}>
          <div className="intro">
            <Intro language={language} />
          </div>
          <div className="project-list">
            {projects.map(project => (
              <div
                key={project.id}
                className={`project-link ${selectedProject && selectedProject.id === project.id ? 'active' : ''}`}
                onClick={() => handleClick(project)}
              >
                {project.name[language]}
              </div>
            ))}
          </div>
        </div>
        <div className="content">
          <Routes>
            <Route path="/project/:id" element={<ProjectWrapper setSelectedProject={setSelectedProject} language={language} />} />
            <Route path="/" element={<RandomThumbnails projects={projects} setSelectedProject={setSelectedProject} language={language} />} />
          </Routes>
        </div>
      </div>
    </div>
  );
}

function ProjectWrapper({ setSelectedProject, language }) {
  const { id } = useParams();
  const project = projects.find(p => p.id === parseInt(id, 10));

  useEffect(() => {
    if (project) {
      setSelectedProject(project);
    }
  }, [id, project, setSelectedProject]);

  if (!project) {
    return <div>Project not found</div>;
  }

  return <ProjectDetail project={project} language={language} />;
}

function RandomThumbnails({ projects, setSelectedProject, language }) {
  const shuffledProjects = [...projects].sort(() => 0.5 - Math.random());
  const positions = [];

  const getRandomPosition = () => {
    let x, y, tooClose;
    const threshold = 15; // 최소 간격을 설정합니다.

    do {
      tooClose = false;
      x = Math.random() * 75 + 15; // 가로 위치 (15% ~ 90%)
      y = Math.random() * 75 + 15; // 세로 위치 (15% ~ 90%)

      // 현재 위치가 다른 위치들과 너무 가까운지 확인합니다.
      for (const pos of positions) {
        const dx = x - pos.x;
        const dy = y - pos.y;
        const distance = Math.sqrt(dx * dx + dy * dy);
        if (distance < threshold) {
          tooClose = true;
          break;
        }
      }
    } while (tooClose);

    positions.push({ x, y });
    return { x, y };
  };

  const handleMouseEnter = (event) => {
    event.currentTarget.style.zIndex = 100;
  };

  const handleMouseLeave = (event) => {
    event.currentTarget.style.zIndex = 1;
  };

  const navigate = useNavigate();

  return (
    <div className="thumbnails">
      {shuffledProjects.map(project => {
        const { x, y } = getRandomPosition();
        return (
          <div
            key={project.id}
            onClick={() => {
              setSelectedProject(project);
              navigate(`/project/${project.id}`);
            }}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            style={{
              position: 'absolute',
              left: `${x}%`,
              top: `${y}%`,
              transform: 'translate(-50%, -50%)'
            }}
          >
            <img src={images[project.thumbnail]} alt={project.name[language]} className="thumbnail" />
          </div>
        );
      })}
    </div>
  );
}

function ProjectDetail({ project, language }) {
  const renderMedia = (media) => {
    switch (media.type) {
      case 'image':
        return (
          <div key={media.src} className="project-image-container">
            <img src={images[media.src]} alt={`Project ${project.name[language]}`} className="project-image" />
            {media.caption && <div className="image-caption">{media.caption[language]}</div>}
          </div>
        );
      case 'lottie':
        return (
          <div key={media.src} className="lottie-container">
            <iframe src={media.src} width="100%" height="100%" frameBorder="0" allow="autoplay; fullscreen" title="Lottie Animation"></iframe>
          </div>
        );
      case 'vimeo':
        return (
          <div key={media.src} className="video-container">
            <iframe
              src={media.src}
              width="100%"
              height="100%"
              frameBorder="0"
              allow="autoplay; fullscreen; picture-in-picture"
              allowFullScreen
              title="Vimeo Video"
            ></iframe>
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <div className="project-container">
      <h2 className="project-title">{project.name[language]}</h2>
      <div className="project-description">
        <div className={`project-text ${language === 'en' ? 'en' : ''}`}>
          <p>{project.text[language]}</p>
        </div>
        <div className="project-detail">
          <table className="project-table">
            <tbody>
              {Object.keys(project.details).map((key, index) => (
                <tr key={index}>
                  <td className="table-category">{key}</td>
                  <td className="table-content">
                    {key === 'Link' ? (
                      <a className="project-content-link" href={project.details[key].url} target="_blank" rel="noopener noreferrer">{project.details[key].content[language]}</a>
                    ) : (
                      project.details[key][language]
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      <div className="project-media">
        {project.images.map(media => renderMedia(media))}
      </div>
    </div>
  );
}

export default App;
