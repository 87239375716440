const intro = {
  ko: {
    main: '풍크트',
    sub: '',
    text: '는 디자인 스튜디오 ',
    studio: '피칸트',
    studioSub: '',
    suffix: '에서 만드는 다양한 사이드 프로젝트를 담는 곳입니다. 좋아하는 것, 재미있는 것, 지나치고 싶지 않은 것들이 모입니다.'
  },
  en: {
    main: 'Punkt',
    sub: ' ',
    text: 'is a place that captures various side projects created by the design studio ',
    studio: 'Pikant',
    studioSub: ' ',
    suffix: '. It gathers things we like, interesting things, and things we don\'t want to miss.'
  }
};

const projects = [
  {
    id: 5,
    name: { ko: '유령고양이와 해골새', en: 'Ghost-Cat and Skeleton-Bird' },
    thumbnail: '04/0.png',
    text: { ko: '곧 공개됩니다.', en: '' },
  details: {
      Year: { ko: '2024', en: '2024' },
      Category: { ko: '캐릭터', en: 'Character'},
      Link: { url: '', content: { ko: '', en: '' } }
    },
    images: [ 
      { type: 'image', src: '04/coming-soon.png' },
     // { src: '04/01.png', caption: { ko: '첫 번째 이미지 캡션', en: 'First image caption' } },
      { src: '04/01.png', caption: { ko: '첫 번째 이미지 캡션', en: 'First image caption' } },
    ]
  },

  {
    id: 4,
    name: { ko: '나무 그림자 채집', en: 'Gathering Tree Shadows' },
    thumbnail: '05/0.png',
    text: { ko: '함부르크에서 채집한 나무 그림자들을 모아서 묶은 책입니다.', en: 'A book compiled of tree shadows collected in Hamburg.' },
    details: {
      Year: { ko: '2024', en: '2024' },
      Category: { ko: '단행본', en: 'Book' },
      Link: { url: 'https://smartstore.naver.com/punktstore', content: { ko: '스마트스토어', en: 'go to store' } }
    },
    images: [

      { type: 'image', src: '05/01.jpg' },
      { type: 'vimeo', src: 'https://player.vimeo.com/video/990863776?background=1' },
      { type: 'image', src: '05/02.jpg' },
      { type: 'image', src: '05/03.jpg' },
      { type: 'image', src: '05/04.jpg' },
      { type: 'image', src: '05/05.jpg' },

    ]
  },
 
  {
    id: 3,
    name: { ko: '아인말 슈톨렌, 비테', en: 'Einmal Stollen, bitte' },
    thumbnail: '03/0.png',
    text: { ko: '베를린에서 보낸 크리스마스를 떠올리며 쓴 단편소설 모음집입니다. 책에 등장하는 인물들은 도토리를 도둑맞은 다람쥐부터 단골 손님에게 슈톨렌을 팔고 싶은 빵집 점원까지 다양합니다. 흐리고 축축한 베를린의 겨울을 버틸 수 있게 해주는 크리스마스의 면면을 담았습니다.', en: 'A collection of short stories reminiscing Christmas spent in Berlin. The characters in the book range from a squirrel who got its acorns stolen to a bakery clerk who wants to sell stollen to regular customers. It captures the aspects of Christmas that help endure the gray and damp winter of Berlin.' },
    details: {
      Year: { ko: '2023', en: '2023' },
      Category: { ko: '단행본', en: 'Book' },
      Size: { ko: '110*140', en: '110*140' },
      Link: { url: 'https://smartstore.naver.com/punktstore', content: { ko: '스마트스토어', en: 'go to store' } }
    },
    images: [
      { type: 'image', src: '03/01.jpg' },
      { type: 'image', src: '03/02.gif' },
      { type: 'image', src: '03/03.jpg' },
      { type: 'image', src: '03/04.jpg' },
      { type: 'image', src: '03/05.png' },
      { type: 'image', src: '03/06.jpg' },
      { type: 'image', src: '03/07.jpg' },
    ]
  },
  {
    id: 2,
    name: { ko: '이웃새의 새해 인사', en: 'Neighbirdhood' },
    thumbnail: '02/0.png',
    text: { ko: '2024년 새해를 맞아 만든 우리 주변의 다양한 새들을 만나볼 수 있는 웹 연하장입니다. 먹이 두 가지를 고르고 잠시 기다리면, 그 먹이를 찾아온 새 한 마리가 새해 인사를 전해줍니다.', en: 'A online New Year’s card created to celebrate 2024, featuring various birds from our surroundings. Choose two types of food and wait for a moment, and a bird that comes to find the food will deliver a New Year’s greeting.' },
    details: {
      Year: { ko: '2024', en: '2024' },
      Category: { ko: '웹 연하장', en: 'Website' },
      Link: { url: 'https://pikant.kr/birds', content: { ko: '새들 만나러 가기', en: 'to the site' } }
    },
    images: [
      { type: 'vimeo', src: 'https://player.vimeo.com/video/986262795?background=1' },
      { type: 'vimeo', src: 'https://player.vimeo.com/video/986269469?background=1' },
      { type: 'image', src: '02/03.jpg' },
      { type: 'image', src: '02/04.jpg' },
         //{ src: '01/1.jpg', caption: { ko: '첫 번째 이미지 캡션', en: 'First image caption' } },
    ]
  },
  {
    id: 1,
    name: { ko: '케이크 연하장', en: 'New year\'s cake' },
    thumbnail: '01/0.png',
    text: { ko: '‘Decorate your New Year’s Cake’은 2023년 새해를 맞아 피칸트가 제작한 웹 연하장입니다. 다양한 케이크 시트와 재료, 장식물을 조합하면 총 4096종의 케이크를 만들 수 있습니다. 사이트 방문자가 케이크를 완성하면 피칸트가 전하는 새해 카드가 케이크의 배경에 나타납니다.', en: 'For the new year, Pikant has created a micro-website where users can decorate their own cake. By combining cake sheets and different decorations, a total of 4096 different cakes can be created. When the cake is finished, a New Year’s greeting from Pikant appers.' },
    details: {
      Year: { ko: '2018 ~', en: '2018 ~' },
      Category: { ko: '웹 연하장', en: 'Website' },
      Link: { url: 'http://pikant.kr/cake', content: { ko: '케이크 만들기', en: 'to the site' } }
    },
    images: [
      { type: 'image', src: '01/1.jpg' },
      { type: 'image', src: '01/2.gif' }, // 캡션이 없는 이미지
      { type: 'image', src: '01/3.png' },
      // { type: 'lottie', src: 'https://lottie.host/embed/f0afe20f-0f21-4577-b897-479b36ca24bf/kZxn6Wa7BW.json' },
      { type: 'vimeo', src: 'https://player.vimeo.com/video/986212412?background=1' },
  
    ]
  },
   {
    id: 0,
    name: { ko: '이대로 괜찮을까', en: 'would it be okay' },
    thumbnail: '00/0.png',
    text: { ko: '피칸트의 두 디자이너는 함께 한국과 독일의 일상을 4컷 만화로 그려 인스타그램에 비정기적으로 연재하고 있습니다. 만화는 책으로도 제작되었습니다.', en: 
      'The two designers of Pikant draw the 4-cut cartoons of daily life in South Korea and Germany and publish them on Instagram at irregular intervals. Comics were also produced as books.'

     },
    details: {
      Year: { ko: '2023', en: '2023' },
      Category: { ko: '단행본/만화', en: 'Book/Comic' },
      Link: { url: 'http://instagram.com/would_it_be_okay', content: { ko: 'instagram', en: 'instagram' } }
    },
    images: [
      { type: 'image', src: '00/01.jpg' },
      { type: 'image', src: '00/02.jpg' },
      { type: 'image', src: '00/03.jpg' },
      { type: 'image', src: '00/04.jpg' },
      { type: 'image', src: '00/05.jpg' },
      
  
    ]
  },

  
  // 다른 프로젝트 추가...
];

export { intro, projects };



//example for caption, lottie, vimeo

// images: [
//   { type: 'image', src: '01/1.jpg', caption: { ko: '첫 번째 이미지 캡션', en: 'First image caption' } },
//   { type: 'image', src: '01/2.jpg' }, // 캡션이 없는 이미지
//   { type: 'lottie', src: '<iframe src="https://lottie.host/embed/f0afe20f-0f21-4577-b897-479b36ca24bf/kZxn6Wa7BW.json" width="100%" height="auto" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>' },
//   { type: 'vimeo', src: '<iframe src="https://player.vimeo.com/video/942232265?background=1" width="2500" height="1666" frameborder="0" allow="autoplay; fullscreen; picture-in-picture; clipboard-write" title="그룬트패턴모션"></iframe>' }
// ]